const pl_PL = {}

pl_PL.strings = {
  addBulkFilesFailed: {
    '0': 'Dodanie %{smart_count} pliku nie powiodÃÂo siÃÂ powodu bÃÂÃÂdu',
    '1': 'Dodanie %{smart_count} plikÃÂ³w nie powiodÃÂo siÃÂ z powodu bÃÂÃÂdÃÂ³w',
  },
  addMore: 'Dodaj wiÃÂcej',
  addMoreFiles: 'Dodaj wiÃÂcej plikÃÂ³w',
  addingMoreFiles: 'Dodawanie kolejnych plikÃÂ³w',
  allowAccessDescription: 'Aby zrobiÃÂ zdjÃÂcie lub nagraÃÂ filmik z uÃÂ¼yciem wbudowanego aparatu, zezwÃÂ³l stronie na dostÃÂp do tego urzÃÂdzenia',
  allowAccessTitle: 'ZezwÃÂ³l na dostÃÂp do aparatu',
  authenticateWith: 'PoÃÂÃÂcz z %{pluginName}',
  authenticateWithTitle: 'Zaloguj siÃÂ do %{pluginName} aby wybraÃÂ pliki',
  back: 'Wstecz',
  browse: 'Wybierz',
  browseFiles: 'Wybierz',
  cancel: 'Anuluj',
  cancelUpload: 'Anuluj wysyÃÂkÃÂ',
  chooseFiles: 'Wybierz pliki',
  closeModal: 'Zamknij okno',
  companionError: 'PoÃÂÃÂczenie z serwisem nie powiodÃÂo siÃÂ',
  companionUnauthorizeHint: 'Aby wylogowaÃÂ siÃÂÃÂ z konta %{provider}, przejdÃÂº pod adres %{url}',
  complete: 'UkoÃÂczono',
  connectedToInternet: 'PoÃÂÃÂczono z Internetem',
  copyLink: 'Skopiuj link',
  copyLinkToClipboardFallback: 'Skopiuj poniÃÂ¼szy adres URL',
  copyLinkToClipboardSuccess: 'Link skopiowany do schowka',
  creatingAssembly: 'Przygotowywanie wysyÃÂania...',
  creatingAssemblyFailed: 'Transloadit: Niepowodzenie przy tworzeniu zbioru',
  dashboardTitle: 'PrzesyÃÂanie plikÃÂ³w',
  dashboardWindowTitle: 'Okno przesyÃÂania plikÃÂ³w (NaciÃÂnij escape aby zamknÃÂÃÂ)',
  dataUploadedOfTotal: '%{complete} z %{total}',
  done: 'UkoÃÂczono',
  dropHereOr: 'UpuÃÂÃÂ pliku tutaj albo %{browse}',
  dropHint: 'UpuÃÂÃÂ swoje pliki tutaj',
  dropPasteBoth: 'UpuÃÂÃÂ pliki tutaj, wklej, albo %{browse}',
  dropPasteFiles: 'UpuÃÂÃÂ pliki tutaj, wklej, albo %{browse}',
  dropPasteFolders: 'UpuÃÂÃÂ pliki tutaj, wklej, albo %{browse}',
  dropPasteImportBoth: 'UpuÃÂÃÂ pliki tutaj, wklej, %{browse} albo zaimportuj z',
  dropPasteImportFiles: 'UpuÃÂÃÂ pliki tutaj, wklej, %{browse} albo zaimportuj z',
  dropPasteImportFolders: 'UpuÃÂÃÂ pliki tutaj, wklej, %{browse} albo zaimportuj z',
  editFile: 'Edytuj plik',
  editing: 'Edycja %{file}',
  emptyFolderAdded: 'Z pustego folderu nie zostaÃÂy dodane ÃÂ¼adne pliki',
  encoding: 'Transkodowanie...',
  enterCorrectUrl: 'Niepoprawny URL: Upewnij siÃÂ, ÃÂ¼e wprowadzasz bezpoÃÂredni adres pliku',
  enterUrlToImport: 'WprowadÃÂº URL, aby zaimportowaÃÂ plik',
  exceedsSize: 'Plik ma rozmiar wiÃÂkszy od dozwolonego %{size}',
  failedToFetch: 'Serwis nie mÃÂ³gÃÂ przetworzyÃÂ podanego linku, zweryfikuj jego poprawnoÃÂÃÂ',
  failedToUpload: 'PrzesyÃÂanie %{file} nie powiodÃÂo siÃÂ',
  fileSource: 'ÃÂ¹rÃÂ³dÃÂo pliku: %{name}',
  filesUploadedOfTotal: {
    '0': 'wysÃÂano %{complete} z %{smart_count} pliku',
    '1': 'wysÃÂano %{complete} z %{smart_count} plikÃÂ³w',
    '2': 'wysÃÂano %{complete} z %{smart_count} plikÃÂ³w',
  },
  filter: 'Filtr',
  finishEditingFile: 'ZakoÃÂcz edycjÃÂÃÂ pliku',
  folderAdded: {
    '0': 'Dodano %{smart_count} plik z %{folder}',
    '1': 'Dodano %{smart_count} plikÃÂ³w z %{folder}',
    '2': 'Dodano %{smart_count} plikÃÂ³w z %{folder}',
  },
  generatingThumbnails: 'Generowanie miniaturek...',
  import: 'Importuj',
  importFrom: 'Importuj z %{name}',
  loading: 'ÃÂadowanie...',
  logOut: 'Wyloguj',
  myDevice: 'Moje urzÃÂdzenie',
  noDuplicates: 'Nie moÃÂ¼na dodaÃÂ i zduplikowaÃÂ pliku \'%{fileName}\', juÃÂ¼ istnieje',
  noFilesFound: 'W tym miejscu brakuje plikÃÂ³w lub katalogÃÂ³w',
  noInternetConnection: 'Brak poÃÂÃÂczenia z Internetem',
  noMoreFilesAllowed: 'Nie moÃÂ¼na dodaÃÂ nowych plikÃÂ³w: trwa wysyÃÂka',
  openFolderNamed: 'OtwÃÂ³rz folder %{name}',
  pause: 'Wstrzymaj',
  pauseUpload: 'Wstrzymaj wysyÃÂkÃÂ',
  paused: 'Wstrzymano',
  poweredBy: 'Dostarczane przez %{uppy}',
  processingXFiles: {
    '0': 'Przetwarzanie %{smart_count} pliku',
    '1': 'Przetwarzanie %{smart_count} plikÃÂ³w',
    '2': 'Przetwarzanie %{smart_count} plikÃÂ³w',
  },
  recordingLength: 'DÃÂugoÃÂÃÂ nagrania %{recording_length}',
  removeFile: 'UsuÃÂ filtr',
  resetFilter: 'Zresetuj filtr',
  resume: 'WznÃÂ³w',
  resumeUpload: 'WznÃÂ³w wysyÃÂkÃÂ',
  retry: 'PonÃÂ³w prÃÂ³bÃÂ',
  retryUpload: 'PonÃÂ³w prÃÂ³bÃÂ wysyÃÂki',
  saveChanges: 'Zapisz zmiany',
  selectFileNamed: 'Wybierz plik %{name}',
  selectX: {
    '0': 'Wybierz %{smart_count}',
    '1': 'Wybierz %{smart_count}',
    '2': 'Wybierz %{smart_count}',
  },
  smile: 'UÃÂmiech!',
  startRecording: 'Zacznij nagrywanie wideo',
  stopRecording: 'Zatrzymaj nagrywanie wideo',
  takePicture: 'ZrÃÂ³b zdjÃÂcie',
  timedOut: 'WysyÃÂka wstrzymana przez %{seconds} sekund, przerywanie.',
  unselectFileNamed: 'Odznacz plik %{name}',
  upload: 'Wgrywanie',
  uploadComplete: 'Wgrywanie ukoÃÂczone',
  uploadFailed: 'Wgrywanie nie powiodÃÂo siÃÂ',
  uploadPaused: 'Wgrywanie wstrzymane',
  uploadXFiles: {
    '0': 'Wgraj %{smart_count} plik',
    '1': 'Wgraj %{smart_count} pliki',
    '2': 'Wgraj %{smart_count} pliki',
  },
  uploadXNewFiles: {
    '0': 'Wgraj +%{smart_count} plik',
    '1': 'Wgraj +%{smart_count} pliki',
    '2': 'Wgraj +%{smart_count} pliki',
  },
  uploading: 'Wgrywanie',
  uploadingXFiles: {
    '0': 'Wgrywanie %{smart_count} pliku',
    '1': 'Wgrywanie %{smart_count} plikÃÂ³w',
    '2': 'Wgrywanie %{smart_count} plikÃÂ³w',
  },
  xFilesSelected: {
    '0': '%{smart_count} plik zaznaczony',
    '1': '%{smart_count} plikÃÂ³w zaznaczonych',
    '2': '%{smart_count} plikÃÂ³w zaznaczonych',
  },
  xMoreFilesAdded: {
    '0': 'dodano %{smart_count} 1 plik wiÃÂcej',
    '1': 'dodano %{smart_count} pliki wiÃÂcej',
    '2': 'dodano %{smart_count} pliki wiÃÂcej',
  },
  xTimeLeft: '%{time} zostaÃÂo',
  youCanOnlyUploadFileTypes: 'MoÃÂ¼esz przesÃÂaÃÂ tylko: %{types}',
  youCanOnlyUploadX: {
    '0': 'MoÃÂ¼esz wgraÃÂ tylko %{smart_count} plik',
    '1': 'MoÃÂ¼esz wgraÃÂ tylko %{smart_count} pliki',
    '2': 'MoÃÂ¼esz wgraÃÂÃÂ tylko %{smart_count} pliki',
  },
  youHaveToAtLeastSelectX: {
    '0': 'Musisz wybraÃÂ przynajmniej %{smart_count} plik',
    '1': 'Musisz wybraÃÂ przynajmniej %{smart_count} pliki',
    '2': 'Musisz wybraÃÂ przynajmniej %{smart_count} pliki',
  },
}

pl_PL.pluralize = function pluralize (n) {
  if (n === 1) {
    return 0
  }
  return 1
}

if (typeof window !== 'undefined' && typeof window.Uppy !== 'undefined') {
  window.Uppy.locales.pl_PL = pl_PL
}

module.exports = pl_PL
